import React from 'react';
import { useStyles } from './styles';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import classNames from 'classnames';

type Props = {
  lang: string;
  isLanguageMenuOpen: boolean;
  handleLanguageChanged: (language: string) => void;
  setIsLanguageMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseProfileMenu: () => void;
};

export const LanguageMenu = ({
  handleLanguageChanged,
  handleCloseProfileMenu,
  setIsLanguageMenuOpen,
  lang,
  isLanguageMenuOpen,
}: Props) => {
  const classes = useStyles();
  const languageMenuRef = useOutsideClick(() => setIsLanguageMenuOpen(false));

  const handleChangeLanguage = (language: string) => {
    setIsLanguageMenuOpen(false);
    handleLanguageChanged(language);
  };

  return (
    <div className={classes.languageMenuContainer}>
      <button
        data-btn='language_btn'
        onClick={() => {
          setIsLanguageMenuOpen(!isLanguageMenuOpen);
          handleCloseProfileMenu();
        }}
        className={classNames(classes.languageSwitchButton, { [classes.buttonOpenMenu]: isLanguageMenuOpen })}
      >
        {lang === 'ru' ? 'RU' : 'EN'}
      </button>
      <div ref={languageMenuRef} className={classes.languageMenu}>
        <button
          onClick={() => handleChangeLanguage('ru')}
          className={classNames(classes.languageMenuButton, { [classes.isSelectedLanguage]: lang === 'ru' })}
        >
          Русский
        </button>
        <button
          onClick={() => handleChangeLanguage('en')}
          className={classNames(classes.languageMenuButton, { [classes.isSelectedLanguage]: lang === 'en' })}
        >
          English
        </button>
      </div>
    </div>
  );
};
