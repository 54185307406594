import React, { useState, useEffect } from 'react';
import { API_SERVER_URL } from 'gatsby-env-variables-fork';
import { AuthModel } from './authContext';

export const useAuthData = () => {
  const [authData, setAuthData] = useState<AuthModel>({isDataFetching: true} as AuthModel);
  const [isLoading, setIsLoading] = useState(true);

  const getAuthData = async () => {
    setAuthData({...authData, isDataFetching: true});
    try {
      const response = await fetch(`${API_SERVER_URL}/api/auth/me`, {
        credentials: 'include',
        referrer: '/',
        cache: 'no-cache',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();

      if (data) {
        setAuthData({...data, isDataFetching: false});
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAuthData();
  }, []);

  return { isLoading, authData };
};
