import { useEffect, useRef } from "react"

// Tracking a click outside the area of an open element (for example, dropdown or tooltip)
// If the click occurred outside the element area, handleOutsideClick function is called
export const useOutsideClick = (handleOutsideClick: () => void) => {
    const ref = useRef<HTMLDivElement | null>(null);

    const onClick = (event: MouseEvent) => {
      const element = event.target as Element;

      if (element.getAttribute('data-btn')) {
        return;
      }

      if (ref.current && !ref.current.contains(element)) {
        handleOutsideClick();
      }
    };

    useEffect(() => {
      document.addEventListener('click', onClick, true);
      return () => {
        document.removeEventListener('click', onClick, true);
      };
    }, []);

    return ref;
}