import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    "@global": {
        body: {
            margin: 0,
        }
    },
    container: {
        fontFamily: 'Montserrat',
        height: '64px',
        display: 'flex',
        alignItems: 'center',
        padding: '16px 40px',
        width: '100%',
        boxSizing: 'border-box',
        backgroundColor: '#3A28A7',
        "@media (max-width: 1200px)": {
            paddingLeft: 32,
            paddingRight: 32
        },
        "@media (max-width: 600px)": {
            paddingLeft: 24,
            paddingRight: 24
        },
        '& a': {
            cursor: 'pointer'
        }
    },
    mainLogo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    authorization: {
        display: 'flex',
        gap: '16px',
        "@media (max-width: 650px)": {
            display: 'none !important',
        }
    },
    primaryButton: {
        color: 'white',
        borderRadius: '54px',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 600,
        padding: '10px 40px',
        border: '1px solid #65C15D',
        background: '#65C15D',
        '&:hover': {
            background: '#97DE6B',
        },

        font: 'inherit',
        cursor: 'pointer',
        outline: 'inherit'
    },
    secondaryButton: {
        color: 'white',
        borderRadius: '54px',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 600,
        padding: '10px 40px',
        border: '1px solid #FFFFFF',
        background: 'none',

        '&:hover': {
            background: '#7770FF',
            borderColor: '#7770FF'
        },

        font: 'inherit',
        cursor: 'pointer',
        outline: 'inherit'
    },
    infoContainer: {
        marginLeft: 'auto',
        display: 'flex',
        gap: '32px',
    },
    logoutContainer: {
        display: 'none !important',
        '@media (max-width: 1440px)': {
            display: 'block !important',
        },
        '@media (max-width: 400px)': {
            display: 'none !important',
        },
    }
})
