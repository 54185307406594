import React, { useState } from "react";
import { useStyles } from "./styles";
import logo from "../../images/page-header/logo.svg";
import { HeaderLinks } from "./header-links/header-links";
import { BurgerMenu } from "./burger-menu/burger-menu";
import { MainMenuSchemeModel } from "./types";
import { ProfileMenu } from "./profile-menu/profile-menu";
import { LanguageMenu } from "./language-menu/language-menu";
import { NODE_LOCATION } from "gatsby-env-variables-fork";
import { Translate } from "../../locales/locales-utils";
import { MANAGE_SITE_URL } from "gatsby-env-variables-fork";
import { navigate } from "gatsby";
import useWindowSize from "../../hooks/change-size";
import { browserRedirectToPage } from "../../helpers/browserRedirectToPage";
import { useAuth } from "../../auth/authContext";
import defaultProfile from "../../images/page-header/default_profile.png";

type Props = {
  mainMenuScheme: MainMenuSchemeModel;
  onLanguageChanged: (language: string) => void;
  lang: string;
};

export const MainMenu = ({
  onLanguageChanged,
  mainMenuScheme,
  lang,
}: Props) => {
  const classes = useStyles();
  const { links, profileMenuLinks } = mainMenuScheme;
  const isRuLocation = NODE_LOCATION === "ru";
  const tabletWidth = useWindowSize().width <= 1440;
  const {
    isAuthorizedUser,
    isDataFetching,
    profileIconSrc,
    nickName,
    firstName,
    lastName,
  } = useAuth();

  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);

  return (
    <div className={classes.container}>
      <a
        className={classes.mainLogo}
        href="/"
        onClick={(e) => {
          e.preventDefault();
          navigate("/");
        }}
      >
        <img src={logo} alt="myquiz" />
      </a>
      <HeaderLinks isAuthorizedUser={isAuthorizedUser} links={links} />
      <div className={classes.infoContainer}>
        {isRuLocation && !tabletWidth && (
          <LanguageMenu
            lang={lang}
            isLanguageMenuOpen={isLanguageMenuOpen}
            handleLanguageChanged={onLanguageChanged}
            handleCloseProfileMenu={() => setIsProfileMenuOpen(false)}
            setIsLanguageMenuOpen={setIsLanguageMenuOpen}
          />
        )}
        {!isDataFetching && !isAuthorizedUser && (
          <div data-buttons="authorization" className={classes.authorization}>
            <button
              className={classes.secondaryButton}
              onClick={() =>
                browserRedirectToPage(`${MANAGE_SITE_URL}/Account/Register`)
              }
            >
              {isRuLocation ? Translate("MainMenu.SignUp") : "Sign up"}
            </button>
            <button
              className={classes.primaryButton}
              onClick={() =>
                browserRedirectToPage(`${MANAGE_SITE_URL}/Account/Login`)
              }
            >
              {isRuLocation ? Translate("MainMenu.Login") : "Log in"}
            </button>
          </div>
        )}
        {!isDataFetching && isAuthorizedUser && (
          <>
            <div
              data-buttons="authorization"
              className={classes.logoutContainer}
            >
              <button
                className={classes.secondaryButton}
                onClick={() =>
                  browserRedirectToPage(`${MANAGE_SITE_URL}/Account/LogOff`)
                }
              >
                {isRuLocation ? Translate("MainMenu.Logout") : "Log out"}
              </button>
            </div>
            <ProfileMenu
              links={profileMenuLinks}
              profileIconSrc={profileIconSrc || defaultProfile}
              isProfileMenuOpen={isProfileMenuOpen}
              setIsProfileMenuOpen={setIsProfileMenuOpen}
              handleCloseLanguageMenu={() => setIsLanguageMenuOpen(false)}
            />
          </>
        )}
      </div>
      <BurgerMenu
        lang={lang}
        handleLanguageChanged={onLanguageChanged}
        links={links}
        isAuthorizedUser={isAuthorizedUser}
        profileIconSrc={profileIconSrc || defaultProfile}
        profileMenuLinks={profileMenuLinks}
        userName={nickName || firstName || lastName || ""}
      />
    </div>
  );
};
