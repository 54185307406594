import { getDomain } from "./getDomain";

const isWindowReady = typeof window !== "undefined";

export const setCookie = (name: string, value: string, days: number, isSupercookie: boolean = false) => {
  if (isWindowReady) {
    const expires = new Date();
    expires.setDate(expires.getDate() + days);
    document.cookie = `${name}=${value};${
      isSupercookie ? `domain=${getDomain()};` : ''
    }expires=${expires.toUTCString()};path=/`;
  }
}

export const getCookie = (name: string) => {
  if (isWindowReady) {
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i].trim();
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
  }

  return null;
}
