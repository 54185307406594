import React from "react";
import i18next from "i18next";
import "/src/locales/locales-settings";

export function Translate(key: any): any {
    return i18next.t(key);
}

export function setCurrentLng(code: string): void {
    i18next.changeLanguage(code);
}

export function getCurrentLang(): string {
    return  i18next.language;
}