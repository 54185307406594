/* eslint-disable import/no-anonymous-default-export */

import React, {
  JSXElementConstructor,
  ReactElement,
  useState,
  useEffect,
} from "react";
import { MainMenu } from "../components/main-menu/main-menu";
import { mainMenuUsScheme, mainMenuRuScheme } from "./mainMenuScheme";

import { NODE_LOCATION } from "gatsby-env-variables-fork";
import { useAuthData } from "../auth/useAuthData";
import { setCurrentLng } from "../locales/locales-utils";
import { getCurrentLang } from "../locales/locales-utils";
import { getCookie, setCookie } from "../helpers/cookiesHelper";
import { AuthProvider } from "../auth/authContext";

interface Props {
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  pageContext: Record<string, string>;
}

export default (props: Props) => {
  const { children, pageContext } = props;
  const { language } = pageContext;
  const { authData } = useAuthData();
  const [lang, setLang] = useState("");

  const actualLanguage = getCookie("lang");
  const isRuActualLanguage = actualLanguage && actualLanguage === "ru";

  const isRuLocation =
    NODE_LOCATION === "ru" &&
    (!language || language === "ru" || getCurrentLang() === "ru");

  const mainMenuScheme = isRuLocation ? mainMenuRuScheme() : mainMenuUsScheme;
  const currentLang =
    isRuLocation && (!actualLanguage || isRuActualLanguage) ? "ru" : "en";

  useEffect(() => {
    setLang(currentLang);
    setCookie("lang", currentLang, 365, true);
  }, [currentLang]);

  const handleChangeLanguage = (language: string) => {
    setCurrentLng(language);
    setLang(language);
    setCookie("lang", language, 365, true);
  };

  return (
    <>
      <AuthProvider value={authData}>
        <MainMenu
          mainMenuScheme={mainMenuScheme}
          onLanguageChanged={handleChangeLanguage}
          lang={lang}
        />
        <main>{React.cloneElement(children, { lang: lang })}</main>
      </AuthProvider>
    </>
  );
};
