/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useStyles } from './styles';
import classNames from 'classnames';
import { LinkModel } from '../types';
import { NODE_LOCATION, MANAGE_SITE_URL } from 'gatsby-env-variables-fork';
import { Translate } from '../../../locales/locales-utils';
import { navigate } from 'gatsby';
import up from '../../../images/page-header/arrow-up.svg';
import down from '../../../images/page-header/arrow-down.svg';
import languageIcon from '../../../images/page-header/links/language.svg';
import { browserRedirectToPage } from '../../../helpers/browserRedirectToPage';

type Props = {
  links: LinkModel[];
  isAuthorizedUser: boolean;
  handleLanguageChanged: (language: string) => void;
  lang: string;
  profileIconSrc: string;
  profileMenuLinks: LinkModel[];
  userName: string;
};

export const BurgerMenu = ({
  isAuthorizedUser,
  links,
  handleLanguageChanged,
  lang,
  profileIconSrc,
  profileMenuLinks,
  userName,
}: Props) => {
  const pathName = typeof window !== "undefined" && window.location.pathname;

  const [isOpen, setIsOpen] = useState(false);
  const [currentLinks, setCurrentLinks] = useState<LinkModel[]>();

  const [isLanguageAccordionOpen, setIsLanguageAccordionOpen] = useState(false);

  const linksRef = useRef<HTMLDivElement>(null);
  const [hasScroll, setHasScroll] = useState(false);

  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

  const classes = useStyles();
  const isRuLocation = NODE_LOCATION === 'ru';

  useEffect(() => {
    let authorizationButtons: HTMLDivElement = document.querySelector('div[data-buttons="authorization"]')!;

    if (isOpen) {
      document.body.style.overflowY = 'hidden';
      if (authorizationButtons) {
        authorizationButtons.style.display = 'none';
      }
    } else {
      document.body.style.overflowY = 'auto';
      if (authorizationButtons) {
        authorizationButtons.style.display = 'flex';
      }
    }
  }, [isOpen]);

  useEffect(() => {
    let actualLinks = isAuthorizedUser
      ? links.filter((x) => x.isCommon || x.isAuthorized)
      : links.filter((x) => x.isCommon || !x.isAuthorized);

    actualLinks = actualLinks.map((x) => {
      if (x.subLinks) {
        let openedLink = x.subLinks.find((y) => (pathName as string).includes(y.href as string));
        return { ...x, isMenuOpen: openedLink ? true : false };
      }
      return { ...x, isMenuOpen: false };
    });

    setCurrentLinks(actualLinks);
  }, [links, isAuthorizedUser, pathName]);

  useEffect(() => {
    const linksDivElement = linksRef.current;

    if (linksDivElement) {
      const hasVerticalScrollbar = linksDivElement.scrollHeight > linksDivElement.clientHeight;
      setHasScroll(hasVerticalScrollbar);
    }
  }, [isOpen, currentLinks]);

  const handleOpenAccordion = (name: string) => {
    const tempLinks = currentLinks?.map((x) => {
      if (x.name === name) {
        if (x.isMenuOpen) {
          return { ...x, isMenuOpen: false };
        } else {
          return { ...x, isMenuOpen: true };
        }
      }
      return { ...x, isMenuOpen: false };
    });
    setCurrentLinks(tempLinks);
  };

  const checkMenuIsOpen = () => currentLinks?.some((x) => x.isMenuOpen);

  return (
    <div className={classes.container}>
      <div
        className={classNames(classes.burgerButton, { [classes.burgerRotateButton]: isOpen })}
        onClick={() => setIsOpen(!isOpen)}
      >
        {!isOpen && <span />}
      </div>
      <div className={classNames(classes.menu, { [classes.menuActive]: isOpen })}>
        <div className={classes.content}>
          <div className={classes.linksContainer} ref={linksRef}>
            {currentLinks &&
              currentLinks.map((x) =>
                x.subLinks ? (
                  <div key={x.name}>
                    <a
                      href={x.href}
                      onClick={() => {
                        handleOpenAccordion(x.name);
                        setIsLanguageAccordionOpen(false);
                        setIsProfileMenuOpen(false);
                      }}
                      className={classNames(classes.accordionLink, { [classes.openedAccordion]: x.isMenuOpen })}
                    >
                      {x.imageSrc && <img src={x.imageSrc} alt={x.href} />}
                      {x.name}
                      <button className={classes.accordionSwitcher}>
                        {x.isMenuOpen ? <img src={down} alt='' /> : <img src={up} alt='' />}
                      </button>
                    </a>
                    <div key={x.href} style={{ display: x.isMenuOpen ? 'block' : 'none' }}>
                      {x.subLinks.map((y) => (
                        <a
                          className={classNames(classes.subLink, {
                            [classes.activeLink]: pathName === y.href,
                          })}
                          key={y.name}
                          onClick={() => {
                            navigate(y.href as string);
                            setIsOpen(false);
                          }}
                        >
                          {y.name}
                        </a>
                      ))}
                    </div>
                  </div>
                ) : (
                  <a
                    key={x.name}
                    className={(pathName as string).includes(x.href as string) && !checkMenuIsOpen() ? classes.activeLink : ''}
                    onClick={() => {
                      x?.target ? window.open(x.href, x.target) : navigate(x.href as string);
                      setIsOpen(!!x?.target);
                    }}
                  >
                    {x.imageSrc && <img src={x.imageSrc} alt={x.href} />}
                    {x.name}
                  </a>
                )
              )}
            {isRuLocation && (
              <>
                <a
                  onClick={() => {
                    setIsLanguageAccordionOpen(!isLanguageAccordionOpen);
                    handleOpenAccordion('');
                    setIsProfileMenuOpen(false);
                  }}
                  className={classNames(classes.accordionLink, classes.languageSwitcher, { [classes.openedAccordion]: isLanguageAccordionOpen })}
                >
                  <img src={languageIcon} alt={'language'} />
                  {lang === 'ru' ? 'Русский' : 'English'}
                  <button className={classes.accordionSwitcher}>
                    {isLanguageAccordionOpen ? <img src={down} alt='' /> : <img src={up} alt='' />}
                  </button>
                </a>
                <div style={{ display: isLanguageAccordionOpen ? 'block' : 'none' }}>
                  <a
                    className={classes.subLink}
                    onClick={() => {
                      handleLanguageChanged(lang === 'ru' ? 'en' : 'ru');
                      setIsLanguageAccordionOpen(false);
                    }}
                  >
                    {lang === 'ru' ? 'English' : 'Русский'}
                  </a>
                </div>
              </>
            )}
          </div>
          {!isAuthorizedUser ? (
            <div className={classes.authorizationContainer} style={{ borderTop: hasScroll ? '2px solid #6049EB' : '' }}>
              <>
                <button
                  className={classes.secondaryButton}
                  onClick={() => browserRedirectToPage(`${MANAGE_SITE_URL}/Account/Register`)}
                >
                  {isRuLocation ? Translate('MainMenu.TabletSignUp') : 'Sign up'}
                </button>
                <button
                  className={classes.primaryButton}
                  onClick={() => browserRedirectToPage(`${MANAGE_SITE_URL}/Account/Login`)}
                >
                  {isRuLocation ? Translate('MainMenu.Login') : 'Log in'}
                </button>
              </>
            </div>
          ) : (
            <>
              <div
                className={classes.profileMenuLinksContainer}
                style={{ display: isProfileMenuOpen ? 'flex' : 'none' }}
              >
                {profileMenuLinks.map((y) => (
                  <a
                    className={classNames(classes.profileMenuLink, {
                      [classes.activeLink]: (pathName as string).includes(y.href as string),
                    })}
                    key={y.name}
                    onClick={() => {
                      navigate(y.href as string);
                      setIsOpen(false);
                    }}
                  >
                    {y.imageSrc && <img src={y.imageSrc} alt={y.href} />}
                    {y.name}
                  </a>
                ))}
              </div>
              <a
                onClick={() => {
                  setIsProfileMenuOpen(!isProfileMenuOpen);
                  handleOpenAccordion('');
                  setIsLanguageAccordionOpen(false);
                }}
                className={classes.profileMenuContainer}
              >
                <img className={classes.profileImg} src={profileIconSrc} alt='profile_img' />
                <div className={classes.profileUserName}>{userName}</div>
                <button className={classes.accordionSwitcher}>
                  {isProfileMenuOpen ? <img src={down} alt='' /> : <img src={up} alt='' />}
                </button>
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
