import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    languageMenuContainer: {
        position: 'relative',
        display: 'flex',
    },
    languageSwitchButton: {
        fontWeight: 600,
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center',
        color: 'white',

        '&:after': {
            content: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'8\' height=\'6\' viewBox=\'0 0 8 6\' fill=\'none\'%3E%3Cpath fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M1.61539 0.599609C0.724481 0.599609 0.278314 1.67675 0.908279 2.30672L3.49407 4.8925C3.88459 5.28303 4.51775 5.28303 4.90828 4.8925L7.49407 2.30671C8.12403 1.67675 7.67786 0.599609 6.78696 0.599609H1.61539Z\' fill=\'white\'/%3E%3C/svg%3E")',
            marginLeft: '10px',
            marginBottom: '5px'
        },

        '& img': {
            borderRadius: '50%',
            width: '40px',
            height: '40px',
        },

        border: 'none',
        font: 'inherit',
        cursor: 'pointer',
        outline: 'inherit',
        background: 'none',
    },
    languageMenu: {
        display: 'none',
        position: 'absolute',
        top: '48px',
        right: '0',
        zIndex: '3',
        filter: 'drop-shadow(0 0 8px rgba(0,0,0,.08)) drop-shadow(0 16px 16px rgba(0,0,0,.08))',
    },
    languageMenuButton: {
        padding: '16px 24px',
        backgroundColor: '#fff',
        width: '160px',
        textAlign: 'left',
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: '600',

        '&:first-of-type': {
            borderRadius: '8px 8px 0 0',
        },

        '&:last-of-type': {
            borderRadius: '0 0 8px 8px',
        },

        '&:hover': {
            backgroundColor: '#f0edfd',
        },

        border: 'none',
        font: 'inherit',
        cursor: 'pointer',
        outline: 'inherit',
        background: 'none',
    },
    buttonOpenMenu: {
        '& ~ div': {
            display: 'block',
        },
    },
    isSelectedLanguage: {
        backgroundColor: '#6049eb',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#6049eb',
        },
    }
})