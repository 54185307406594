import React from "react";
import i18n from "i18next"
import { initReactI18next } from "react-i18next";

import * as libraryEn from './en/translation.json';
import * as libraryRu from './ru/translation.json';
import { NODE_LOCATION } from 'gatsby-env-variables-fork';

const resources = {
    en: {
        translation: libraryEn
    },
    ru: {
        translation: libraryRu
    }
};

i18n.use(initReactI18next)
    .use({
        type: "postProcessor",
        name: "formatted-text",
        process: (value: string) => {
            if (value.match(/^ *html: */)) {
                return (
                    <span dangerouslySetInnerHTML={{ __html: value.replace(/^ *html: */, "") }} />
                )
            }
            return value;
        },
    })
    .init({
        postProcess: "formatted-text",
        lng: NODE_LOCATION !== 'us' ? "ru" : "en",
        fallbackLng: NODE_LOCATION !== 'us' ? ["ru", "en"] : ["en"],
        compatibilityJSON: "v2",
        resources: resources,
        debug: true,
        interpolation: {
            escapeValue: false
        },
    });

export { i18n };
