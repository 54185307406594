export const getDomain = () => {
  const host = window.location.hostname;

  if (host.toLowerCase() === 'localhost') {
    return host;
  }

  const ipRegEx = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;

  if (ipRegEx.test(host)) {
    return host;
  }

  const hostArray = host.split('.');

  if (hostArray.length === 1) {
    return host;
  }

  if (hostArray.length > 1) {
    return '.' + hostArray[hostArray.length - 2] + '.' + hostArray[hostArray.length - 1];
  }

  return host;
};
