import React, { useState, useEffect } from 'react';
import { LinkModel } from '../types';
import { useStyles } from './styles';
import { navigate } from 'gatsby';
import classNames from 'classnames';
import arrowUp from '../../../images/page-header/icons/arrow_up_icon.svg';
import arrowDown from '../../../images/page-header/icons/arrow_down_icon.svg';

type Props = {
  links: LinkModel[];
  isAuthorizedUser: boolean;
};

export const HeaderLinks = ({ links, isAuthorizedUser }: Props) => {
  const pathName = typeof window !== "undefined" && window.location.pathname;

  const [currentLinks, setCurrentLinks] = useState<LinkModel[]>(links);
  const classes = useStyles();

  useEffect(() => {
    const actualLinks = isAuthorizedUser
      ? links.filter((x) => x.isCommon || x.isAuthorized)
      : links.filter((x) => x.isCommon || !x.isAuthorized);

    setCurrentLinks(actualLinks);
  }, [links, isAuthorizedUser]);

  const handleOpenMenu = (name: string) => {
    const tempLinks = currentLinks?.map((x) => {
      if (x.name === name) {
        return { ...x, isMenuOpen: true };
      }
      return { ...x };
    });
    setCurrentLinks(tempLinks);
  };

  const handleCloseMenu = () => {
    const tempLinks = currentLinks?.map((x) => {
      if (x.isMenuOpen) {
        return { ...x, isMenuOpen: false };
      }
      return { ...x };
    });
    setCurrentLinks(tempLinks);
  };

  const isActiveSubLink = (link: LinkModel) => {
    const isIncludesActiveSubLink = link.subLinks && link.subLinks.find((x) => pathName && (pathName as string).includes(x.href as string));
    return isIncludesActiveSubLink;
  };

  return (
    <div className={classes.links}>
      {currentLinks &&
        currentLinks.map((link) => (
          <div className={classes.linkContainer} key={link.name}>
            <a
              className={classNames(classes.link, {
                [classes.activeLink]: (pathName && (pathName as string).includes(link.href as string)) || isActiveSubLink(link) || link.isMenuOpen,
              })}
              onMouseLeave={handleCloseMenu}
              onMouseEnter={() => handleOpenMenu(link.name)}
              href={link.href || 'javascript:;'}
              onClick={(e) => {
                if (link.href) {
                  e.preventDefault();
                  navigate(link.href);
                }
              }}
            >
              {link.imageSrc && <img src={link.imageSrc} alt={link.href} />}
              {link.name}
              {link.subLinks && !link.isMenuOpen && <img src={arrowDown} alt='arrowDown' />}
              {link.subLinks && link.isMenuOpen && <img src={arrowUp} alt='arrowUp' />}
            </a>
            {link.subLinks && (
              <div
                onMouseLeave={handleCloseMenu}
                onMouseEnter={() => handleOpenMenu(link.name)}
                className={classes.submenu}
                style={{ display: link.isMenuOpen ? 'block' : 'none' }}
              >
                {link.subLinks.map((x) => (
                  <a
                    className={classNames(classes.submenuLink, {
                      [classes.activeSublink]: pathName === x.href,
                    })}
                    href={`${x.href}`}
                    key={x.name}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(x.href as string);
                    }}
                  >
                    {x.imageSrc && <img src={x.imageSrc} alt={x.href} />}
                    {x.name}
                  </a>
                ))}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};
