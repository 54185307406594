import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    profile: {
        display: 'flex',
        alignItems: 'center',
        gap: '32px',
        '& button': {
            fontWeight: 600,
            fontSize: '16px',
            display: 'flex',
            alignItems: 'center',
            color: 'white',

            '&:after': {
                content: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'8\' height=\'6\' viewBox=\'0 0 8 6\' fill=\'none\'%3E%3Cpath fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M1.61539 0.599609C0.724481 0.599609 0.278314 1.67675 0.908279 2.30672L3.49407 4.8925C3.88459 5.28303 4.51775 5.28303 4.90828 4.8925L7.49407 2.30671C8.12403 1.67675 7.67786 0.599609 6.78696 0.599609H1.61539Z\' fill=\'white\'/%3E%3C/svg%3E")',
                marginLeft: '10px',
                marginBottom: '5px'
            },

            '& img': {
                borderRadius: '50%',
                width: '40px',
                height: '40px',
            },

            border: 'none',
            font: 'inherit',
            cursor: 'pointer',
            outline: 'inherit',
            background: 'none',
        },
        "@media (max-width: 1440px)": {
            display: 'none',
        }
    },
    profileMenu: {
        display: 'none',
        position: 'absolute',
        borderRadius: '8px',
        padding: '16px',
        width: '230px',
        color: 'white',
        zIndex: 3,
        top: '72px',
        right: '28px',
        background: '#3A28A7',

        '& img': {
            filter: 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7470%) hue-rotate(49deg) brightness(97%) contrast(111%)',
        },
    },
    buttonOpenMenu: {
        '& ~ div': {
            display: 'block',
        },
    },
    profileMenuItem: {
        color: '#FFFFFF',
        padding: '14px 24px',
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: 600,
        display: 'flex',
        gap: '24px',
        textDecoration: 'none',
        '&:hover': {
            background: '#2B1C88',
            borderRadius: '8px',
            cursor: 'pointer'
        },
    },
    profileSubMenu: {
        padding: '8px',
        background: '#FFFFFF',
        borderBottom: '1px solid #CBCEDA',
    },
})