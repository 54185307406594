import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  links: {
    display: 'flex',
    alignItems: 'center',
    gap: '40px',
    marginLeft: 'auto',
    "@media (max-width: 1440px)": {
      display: 'none',
    }
  },
  linkContainer: {
    position: 'relative',
  },
  link: {
    fontWeight: 700,
    fontSize: '14px',
    color: '#C0B6FA',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
    textDecoration: 'none',
    '& img': {
      filter: 'brightness(0) saturate(100%) invert(67%) sepia(86%) saturate(463%) hue-rotate(197deg) brightness(99%) contrast(114%)',
    },

    '&:hover': {
      color: '#FFFFFF',
      '& img': {
        filter: 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7470%) hue-rotate(49deg) brightness(97%) contrast(111%)',
      },
    },
  },
  submenu: {
    background: '#3A28A7',
    position: 'absolute',
    borderRadius: '8px',
    top: '52px',
    left: '-7px',
    zIndex: 3,
    padding: '16px',
    width: 'max-content',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '-32px',
      left: '0',
      height: '34px',
      width: '180px',
    }
  },
  submenuLink: {
    fontWeight: 700,
    fontSize: '14px',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
    padding: '12px 24px',
    borderRadius: '8px',
    textDecoration: 'none',
    '&:hover': {
      background: '#2B1C88',
    }
  },
  activeLink: {
    color: '#FFFFFF !important',
    '& img': {
      filter: 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7470%) hue-rotate(49deg) brightness(97%) contrast(111%)',
    },
  },
  activeSublink: {
    background: '#6B53FD',
  }
});
