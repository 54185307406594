import React, { createContext, useContext, ReactNode } from 'react';

export type AuthModel = {
  isAuthorizedUser: boolean;
  isDataFetching: boolean;
  nickName?: string;
  firstName?: string;
  lastName?: string;
  profileIconSrc?: string;
};

const AuthContext = createContext<AuthModel | undefined>(undefined);

type AuthProviderProps = {
  children: ReactNode;
  value: AuthModel;
};

const AuthProvider = ({ children, value }: AuthProviderProps) => {
  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth };
